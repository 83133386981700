<template>
  <b-overlay :show="formShow" rounded="true" :fixed="true" no-fade>
    <b-card>
      <b-row>
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col" class="bg-primary text-white align-middle" style="font-size: 1.2em;">
                Add New Survey
              </th>
              <th scope="col" class="bg-primary text-white d-flex justify-content-end align-middle">
                <b-button type="submit" @click.prevent="formSubmitted" variant="warning" class="float-right animate__animated animate__fadeIn">
                  Save Survey
                </b-button>
              </th>
            </tr>
          </thead>
        </table>
      </b-row>

      <validation-observer ref="surveyRules">
        <b-form>
          <!-- <b-row class="d-flex justify-content-end mt-1">
            
          
          </b-row> -->
          <b-row class="mt-1">
            <!-- First Name -->
            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group>
                <label for="firstName">First Name</label>
                <validation-provider name="First Name" #default="{ errors }" rules="required">
                  <b-form-input id="firstName" type="text" v-model="surveyData.firstName"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Last Surname -->
            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="Last Name" label-for="lastName">
                <validation-provider name="Last Name" #default="{ errors }" rules="required">
                  <b-form-input id="lastName" type="text" v-model="surveyData.lastName"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Email -->
            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="Email" label-for="email">
                <validation-provider name="Email" #default="{ errors }" rules="required|email">
                  <b-form-input id="email" type="text" v-model="surveyData.emailAdress"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- School Name -->
            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="School Name" label-for="schoolName">
                <validation-provider name="School Name" #default="{ errors }" rules="required">
                  <b-form-input id="schoolName" type="text" v-model="surveyData.schoolName"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Class Size -->
            <b-col cols="12" sm="6" md="4" xl="1">
              <b-form-group label="Class Size" label-for="classSize">
                <validation-provider name="Class Size" #default="{ errors }" rules="required">
                  <b-form-input id="classSize" type="text" v-model="surveyData.classSize"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Grade Level -->
            <b-col cols="12" sm="6" md="4" xl="2">
              <b-form-group label="Grade Level" label-for="gradeLevel">
                <validation-provider name="Grade Level" #default="{ errors }" rules="required">
                  <!-- <b-form-input id="gradeLevel" type="text" v-model="surveyData.gradeLevel"></b-form-input> -->
                  <v-select id="gradeLeves" v-model="surveyData.gradeLevel" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title" :reduce="(val) => val" :clearable="false" :options="gradeLevels" @option:selected="selectedLevelValue(surveyData.gradeLevel)" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Event -->
            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="Questionnaire" label-for="event" id="eventLabel">
                <validation-provider name="event" #default="{ errors }" rules="required">
                  <v-select id="event" v-model="surveyData.event" :disabled="eventTypes.length == 0" :options="eventTypes" :reduce="(val) => val" :filterable="true" @option:selected="selectedEvents" label="title" :clearable="false" input-id="event" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Observer -->
            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="Observer" label-for="observer">
                <validation-provider name="Observer" #default="{ errors }" rules="required">
                  <!-- <b-form-input id="gradeLevel" type="text" v-model="surveyData.observer" disabled></b-form-input> -->
                  <v-select
                    id="gradeLeves"
                    v-model="surveyData.observer"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="fullName"
                    :reduce="(val) => val"
                    :clearable="false"
                    :options="observers"
                    @option:selected="selectedObserver(surveyData.observer)"
                    :disabled="!$Can('show_survey_observers')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group>
                <label for="surveyDate">Survey Date</label>
                <validation-provider name="Survey Date" #default="{ errors }" rules="required">
                  <flat-pickr id="surveyDate" class="form-control" :config="{ dateFormat: 'm-d-Y' }" placeholder="MM-DD-YYYY" v-model="surveyData.surveyDate" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- General Comment -->
            <b-col cols="12" xl="12">
              <b-form-textarea id="generalComment" v-show="$Can('save_comments')" v-model="surveyData.generalComment" placeholder="General internal comments - not displayed on PDF" rows="3" max-rows="6"></b-form-textarea>
            </b-col>

            <b-row class="mt-2" v-if="groups.length > 0">
              <b-col cols="12" xl="12" md="12" sm="12" style="margin-bottom: 1rem;">
                <b-tabs pills vertical nav-class="nav-left">
                  <b-tab :title="group.groupName.length > 30 ? group.groupName.slice(0, 30) + '...' : group.groupName" v-for="group in groups" :key="group.id">
                    <b-col cols="12" xl="12" md="12" sm="12">
                      <p style="padding-left: 1rem;">
                        <i> {{ group.groupName }}</i>
                      </p>
                      <b-table hover responsive fixed style=" height: 75vh;" sticky-header="1000px" head-variant="dark" :items="group.questions" :fields="fields" show-empty empty-text="No questions found">
                        <template #cell(questions)="data">
                          <span>{{ data.item.title }}</span>
                          <!-- <span v-if="data.item.status === 'A'">{{ data.item.title }}</span>
                          <span v-else v-show="$Can('save_comments')"><b>{{ data.item.title }}</b></span> -->
                        </template>

                        <template #cell(answer)="data">
                          <validation-provider :name="data.item.title" #default="{ errors }">
                            <!-- status A Answer type -->
                            <v-select
                              v-if="data.item.status === 'A'"
                              :id="data.item.title"
                              :options="answerTypes"
                              :reduce="(val) => val"
                              :filterable="false"
                              :clearable="false"
                              v-model="data.item.commentAnswer"
                              :input-id="data.item.id.title"
                              @option:selected="selectedGroupValue(data.item)"
                              @input="takeAQuestionAnswer"
                            />
                            <!-- status B Comment type -->
                            <b-form-textarea v-else :id="data.item.id.title" placeholder="Comment here" rows="2" debounce="500" no-resize v-model="data.item.commentAnswer" @input="takeAQuestionAnswer" @change="selectedGroupValue(data.item)" style="width:100%"></b-form-textarea>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </template>
                      </b-table>
                    </b-col>
                  </b-tab>
                </b-tabs>
              </b-col>
            </b-row>
            <b-col cols="12" xl="12" md="12" class="text-center mt-2" v-else>
              <h3>
                Please Choose Questionnaire Type
              </h3>
            </b-col>
            <!-- <b-col cols="12" class="mb-5">
              <b-button
                type="submit"
                @click.prevent="formSubmitted"
                variant="primary"
                class="mr-1 float-right"
              >
                Save
              </b-button>
            </b-col> -->
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </b-overlay>
</template>

<script>
import { BCol, BRow, BTab, BTabs, BCard, BAlert, BLink, VBModal } from 'bootstrap-vue';
import { ref, onUnmounted } from '@vue/composition-api';

import store from '@/store';
import settingsStoreModule from '../settingsStoreModule';
import ImageCropper from '@core/components/image-cropper/ImageCropper.vue';
import { required, alphaNum, email } from '@validations';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.us';
import router from '@/router';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import axiosIns from '@/libs/axios';
import _ from 'lodash';
import { t } from '@/@core/libs/i18n/utils';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import 'animate.css';

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,

    vSelect,
    Cleave,
    ToastificationContent,
    ImageCropper,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    Ripple,
    'b-modal': VBModal,
  },

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });
  },

  data: function() {
    var surveyData = {
      surveyDate: null,
      firstName: null,
      lastName: null,
      emailAdress: null,
      schoolName: null,
      classSize: null,
      gradeLevel: null,
      observer: null,
      event: null,
      generalComment: null,
      questionAnswer: [],
      //groups: [],
    };

    const statusOptions = [
      { label: 'Active', value: 'active' },
      { label: 'Passive', value: 'passive' },
    ];

    return {
      gradeLevels: [],
      surveyData: surveyData,
      baseURL: store.state.app.baseURL,
      userData: JSON.parse(localStorage.getItem('userData')),
      required,
      alphaNum,
      email,
      observers: [],
      formShow: false,
      groups: [],
      allGroupsData: [],
      allQuestionnaires: [],
      ans: null,
      answerTypes: [1, 2, 3, 4, 'Not Observed'],
      eventTypes: [],
      fields: [
        {
          key: 'questions',
          label: 'questions',
          thStyle: { width: '70%' },
        },
        {
          key: 'answer',
          label: 'answer',
          thStyle: { width: '30%' },
          thClass: 'text-center',
          tdClass: 'text-center ',
        },
      ],
    };
  },
  mounted() {
    this.userDetails();
    this.getQuestionnaires();
    this.getObservers();
  },

  created() {},

  watch: {
    // 'surveyData.event': {
    //   handler: function(val, before) {
    //     console.log(val);
    //   },w
    // },
  },

  methods: {
    takeAQuestionAnswer(ans) {
      this.ans = ans;
    },
    selectedGroupValue(val) {
      //   let findedGroup = this.allGroupsData.find((e) => e.id == val.groupId);
      this.surveyData.questionAnswer.forEach((question) => {
        if (question.questionId == Number(val.id)) {
          question.commentAnswer = this.ans;
        }
      });
    },
    selectedLevelValue(selectedLevel) {
      this.surveyData.event = null;
      this.groups = [];
      this.eventTypes = [];
      this.allQuestionnaires.forEach((q) => {
        q.gradeLevels.forEach((level) => {
          if (level.id === selectedLevel.id) {
            this.eventTypes.push(q);
          }
        });
      });
    },
    selectedObserver(selectedObserver) {
      this.surveyData.observer = selectedObserver.fullName;
    },
    selectedEvents(event) {
      if (event) {
        this.surveyData.questionAnswer = [];
        //this.surveyData.groups = [];

        event.groups.forEach((group) => {
          group.questions.forEach((question) => {
            question.commentAnswer = question.status === 'A' ? 'Not Observed' : null;
          });
        });

        this.groups = event.groups ? event.groups : [];
        this.groups.forEach((element) => {
          element.questions.forEach((q) => {
            let questionAnswer = {
              questionId: q.id,
              status: q.status,
              groupId: q.groupId,
              //groupTitle: element.groupName,
              //commentTitle: q.title,
              commentAnswer: q.status === 'A' ? 'Not Observed' : null,
              //event: q.event ? q.event : "default",
            };
            this.surveyData.questionAnswer.push(questionAnswer);
          });
          element.questions = _.orderBy(element.questions, ['status'], ['asc']);
          // this.surveyData.groups.push(
          //   _.pick(element, [
          //     "id",
          //     "groupName",
          //     "event",
          //     "status",
          //     "created_at",
          //   ])
          // );
        });
      }
    },
    getGradeLevels() {
      store
        .dispatch('settings/fetchGradeLevels', [])
        .then((res) => {
          this.gradeLevels = [];
          res.data.forEach((gl) => {
            this.gradeLevels.unshift({
              id: gl.id,
              title: gl.title,
              status: gl.status,
            });
          });
          this.formShow = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getQuestionnaires() {
      this.formShow = true;
      store
        .dispatch('settings/fetchQuestionnaires')
        .then((res) => {
          res.data.forEach((item) => {
            let obj = {
              id: item.id,
              title: item.title,
              gradeLevels: item.gradeLevels,
              status: item.status,
              groups: [],
              chartColour: item.chartColour,
              // questions: [],
            };
            if (item.groups.length > 0) {
              item.groups.forEach((group) => {
                obj.groups.push(group);
                // if (group.questions.length > 0) {
                //   group.questions.forEach((question) => {
                //     obj.questions.push(question);
                //   });
                // }
              });
            }
            this.allQuestionnaires.unshift(obj);
            this.getGradeLevels();
          });
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    userDetails() {
      axiosIns
        .get(`user/${this.userData.id}`)
        .then((res) => {
          this.userData = res.data;
          this.surveyData.observer = this.userData.fullName;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getObservers() {
      this.observers = [];
      axiosIns
        .get(`existingObservers`)
        .then((res) => {
          this.observers = res.data.data;
          // this.surveyData.observer = this.userData.fullName;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getGroupWithQuestions(event) {
      store
        .dispatch('settings/fetchGroups', event.id)
        .then((res) => {
          this.groups = res.data ? res.data : [];
          this.groups.forEach((element) => {
            element.questions.forEach((q) => {
              let questionAnswer = {
                questionId: q.id,
                status: q.status === 'A' ? 'question' : 'comment',
                groupId: q.groupId,
                groupTitle: element.groupName,
                commentTitle: q.title,
                commentAnswer: q.status === 'A' ? 'Not Observed' : null,
                event: q.event ? q.event : 'default',
              };
              this.surveyData.questionAnswer.push(questionAnswer);
            });
            element.questions = _.orderBy(element.questions, ['status'], ['asc']);
            // this.surveyData.groups.push(
            //   _.pick(element, [
            //     "id",
            //     "groupName",
            //     "event",
            //     "status",
            //     "created_at",
            //   ])
            // );
          });
          this.formShow = false;
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },
    formSubmitted() {
      this.formShow = true;
      const self = this;
      this.$refs.surveyRules.validate().then((success) => {
        if (success) {
          self.surveyData.event.groups.forEach((group) => {
            group.questions.forEach((question) => {
              self.surveyData.questionAnswer.forEach((answer) => {
                if (answer.questionId == question.id) {
                  answer.surveyId = self.surveyData.event.id;
                  answer.status = question.status;
                }
              });
            });
          });
          store
            .dispatch('settings/addNewSurvey', this.surveyData)
            .then((res) => {
              this.toastMessage('Survey');
              setTimeout(function() {
                self.formShow = false;
                router.push({ name: 'cpat-list' });
              }, 100);
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.formShow = false;
        }
      });
    },
    toastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Successful',
          text: '✔️ ' + val + ' Add Successful',
          icon: 'ThumbsUpIcon',
          variant: 'success',
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#eventLabel__BV_label_ {
  text-decoration: underline;
}

.animate__animated.animate__fadeIn {
  --animate-duration: 3s;
}
</style>
